import React from 'react'
import { NavLink } from 'react-router-dom'
import customerservice from '../assets/customerservice.webp'
import edgeimage from '../assets/edgeimage.gif'
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function Edge() {
  return (
    <>
    <Helmet>
       <title>Wifi Solutions | Edge Computing</title>
        <meta property="og:title" content="Wifi Solutions - Edge Computing" />
        <meta property="og:description" content="Our primary solutions are geared towards getting you ready to build, develop, deploy, and manage your infrastructure and applications from the edge to the core to the cloud." />
        <meta property="og:image" content={logoblue}/>
        <meta property="og:url" content="https://wifillp.com/edge"/>
      </Helmet>
      <section className='pagenamesection flexcenter'>
        <div className="container-fluid p-0 flexcenter" >
          <div className="container text-center">
            <h2>Edge Computing Solutions</h2>
            <p><NavLink to='/' style={{textDecoration:'none',color:'white'}}>Home</NavLink> / Edge Computing Solutions</p>
          </div>

        </div>

      </section>
      <section className='edgemainsection pt-5 pb-5'>
        <div className="container-fluid">
          <h1 className='headtext text-center'>Making Transformation from Edge to Core to Cloud Possible</h1>
          <div className="container">
            <img className='img-fluid' src={edgeimage} alt="" />
          </div>
          <div className="container servicepagecontain p-0">
            <div className='pt-4 pb-4 ps-4 pe-4 text-center' style={{ backgroundColor: "#F8F9FA" }}>
              <p className='textjustify'>Our primary solutions are geared towards getting you ready to build, develop, deploy, and manage your infrastructure and applications from the edge to the core to the cloud.</p>
            </div>
            <div className='pt-4 pb-4 ps-4 pe-4 servicepagebotcon'>
              <p className='text-center'>
                Hybrid Workspace <br />
                Unified Communication and Collaboration <br />
                Edge Infrastructure <br />
                Edge Networking and Security <br />
                Converged Data Center Solutions <br />
                Device as a Service
              </p>
              <p className='text-center'><b>Ready to strengthen your competitive edge?</b></p>
              <div className='w-100 flexcenter'>
                <NavLink className='btn btn-primary' to='/contact'>Let us know what you need</NavLink>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container mt-5">
            <p className='text-center' style={{color: '#2980B9'}}><b>Read-at-a-glance</b></p>
            <div className="row p-0">
              <div className="col-md-6 flexcenter">
                  <div className="container flexcenter">
                      <img width='450px' className='img-fluid' src={customerservice} alt="" />
                  </div>
              </div>
              <div className="col-md-6 flexcenter">
                <div className="container">
                  <p className='textjustify'><b>How IT Teams Can Support Hybrid Workforces and Remote Learning Setups</b></p>
                  <ul className='textjustify'>
                    <li>Educate end users about the proper use of applications to maintain network security.</li>
                   <li> Require a work profile for each employee to keep them from using  their personal accounts.</li>
                   <li> Maximize remote access support software to troubleshoot technical issues.</li>
                   <li> Expand VPN services to accommodate more remote workers and closely monitor VPN usage among employees.</li>
                    <li>Prevent unnecessary traffic from disrupting network connectivity and performance by reviewing VPN rules and monitoring usage.</li>
                    <li>Utilize cloud applications that can make digital assets more accessible to remote users.</li>
                   <li> Integrate collaboration and communication tools into your setup to enable effective teamwork.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default Edge