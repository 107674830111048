import React from 'react'
import astronaut from '../assets/astronaut.webp'
import { NavLink } from 'react-router-dom'

function Pagenotfound() {
  return (
    <>
            <section className='maincontainer'>
                <div className="container-fluid p-0 flexcenter">
                    <div className="row w-100">
                        <div className="col-md-6 flexcenter">
                                <div className="container flexcenter">
                                    <img width='700px' className='img-fluid' src={astronaut} alt="" />
                                </div>
                        </div>
                        <div className="col-md-6 flexcenter">
                            <div className="container errortextcontain" style={{textAlign:'center'}}>
                                <h1 style={{ fontSize: '100px', fontWeight: 'bold' }}>404</h1>
                                <h3>Page not found</h3>
                                <p>Looks like you are lost</p>
                                <p>Go to <NavLink to='/'>homepage</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}

export default Pagenotfound