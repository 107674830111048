import React,{useState,useRef} from 'react'
import { NavLink } from 'react-router-dom'
import WifiLogoWhite from '../assets/WifiLogoWhite.png'

function Navbar() {
    const [navControl, setnavControl] = useState(true)
    const [sideInsideopen, setsideInsideopen] = useState(true)
    // 
    const sideinside = useRef()
    const sideref = useRef()
    // const navRef = useRef()
    function openNav() {
        if (navControl) {
            // sideref.current.style.height = "100vh";
            sideref.current.style.opacity = '100%';
            sideref.current.style.display = 'flex';
            // sideref.current.style.borderRadius = "0%";
           
        } else {
            // sideref.current.style.height = "0";
            sideref.current.style.opacity = '0%';
            sideref.current.style.display = 'none';
            // sideref.current.style.borderRadius = "0% 0% 100% 100%";
        }

    }
    function sideInsideopenfunc() {
        if (sideInsideopen) {
            sideinside.current.style.height = '210px';

        } else {
            sideinside.current.style.height = '0px';
        }


    }
    return (
        <>
            <nav className="navbar bg-body-tertiary w-100">
                <div className="container-fluid">
                    <div className='ms-4'>
                        <NavLink to='/' style={{textDecoration:'none'}}>
                        <img className='img-fluid' width='70px' src={WifiLogoWhite} alt="" />
                        </NavLink>
                    </div>
                    <div className="d-flex">
                        <div className={navControl ? "hamburger" : "hamburger clicked"} onClick={() => { openNav(); setnavControl(!navControl) }}>
                            <div className="line line-1"></div>
                            <div className="line line-2"></div>
                            <div className="line line-3"></div>
                        </div>
                    </div>
                </div>
            </nav>
            <div ref={sideref} className="sidenav flexcenter">
                {/* <div class="closebtn" onClick={() => { openNav(); setnavControl(!navControl) }}>&times;</div> */}
                <div className="container mt-5 pt-5">
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/' onClick={() => { openNav(); setnavControl(!navControl) }}>Home</NavLink>
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/about' onClick={() => { openNav(); setnavControl(!navControl) }}>About</NavLink>
                    <h1 className='Sidenavlinks mb-0' style={{cursor:'pointer'}} onClick={() => { sideInsideopenfunc(); setsideInsideopen(!sideInsideopen) }}>Our Expertise &nbsp;<i className={!sideInsideopen ? "arrow down" : "arrow"} ></i></h1>
                    <div ref={sideinside} className="sidenavopen" >
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/edge' onClick={() => { openNav(); setnavControl(!navControl); sideInsideopenfunc(); setsideInsideopen(!sideInsideopen) }}>Edge</NavLink>
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/core' onClick={() => { openNav(); setnavControl(!navControl); sideInsideopenfunc(); setsideInsideopen(!sideInsideopen) }}>Core</NavLink>
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/cloud' onClick={() => { openNav(); setnavControl(!navControl); sideInsideopenfunc(); setsideInsideopen(!sideInsideopen) }}>Cloud</NavLink>
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/services' onClick={() => { openNav(); setnavControl(!navControl); sideInsideopenfunc(); setsideInsideopen(!sideInsideopen) }}>Services</NavLink>

                    </div>
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/contact' onClick={() => { openNav(); setnavControl(!navControl) }}>Contact Us</NavLink>
                </div>

            </div>
        </>
    )
}

export default Navbar