import React from 'react'

function Footer() {
    return (
        <>
            <section className='sitefooter'>
                <div className="container-fluid w-100 foottopcontain flexcenter">
                    <div className="container">
                        <div className="row p-0">
                            <div className="col-md-3">
                                {/* <div className="container text-center">
                                    <p><b>Manila</b> (+632) 86895000</p>

                                    <p><b>Cebu</b> (+32) 2602071</p>
                                </div> */}
                                <div className="container text-center">
                                    <p><b>Toll-Free Customer Service</b></p>
                                    <p>Tel:<a style={{ textDecoration: 'none', color: 'black' }} href="tel:04714014520"><b> 0471 4014520</b></a></p>
                                </div>
                            </div>
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="container">
                                    <p className='text-center'>Inquire now at <b><a style={{ textDecoration: 'none', color: 'black' }} href="mailto:info@wifillp.com">info@wifillp.com</a> </b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid w-100 footbotcontain flexcenter">

                    <div className="container w-100 flexcenter" >
                        <p className='mb-0 text-center'>Copyright © 2024. All Rights Reserved.</p>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Footer