import React, { useEffect, useRef, useState } from 'react'
// 
import { NavLink } from 'react-router-dom'
// 
import homeimg2 from '../assets/homeimg2.webp'
import box1 from "../assets/box1.webp";
import box2 from "../assets/box2.webp";
import box3 from "../assets/box3.webp";
import box4 from "../assets/box4.webp";
// 
import homegif2 from "../assets/homegif2.gif"
// 
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function Home() {
  const cardRef = useRef(null);
  const [mouseHover, setMouseHover] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });

  const SCALE_X = 4;
  const SCALE_Y = 8;

  const handleMouseMove = (e) => {
    if (!mouseHover) return;

    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setMousePosition({ x, y });
    setCardSize({
      width: cardRef.current.offsetWidth || 0,
      height: cardRef.current.offsetHeight || 0,
    });

    cardRef.current.style.transform = `perspective(1000px) rotateX(${(y / cardSize.height) * -(SCALE_Y * 2) + SCALE_Y
      }deg) rotateY(${(x / cardSize.width) * (SCALE_X * 2) - SCALE_X
      }deg) translateZ(10px)`;
  };

  const resetTransform = () => {
    setMouseHover(false);
    cardRef.current.style.transform =
      'perspective(600px) rotateX(0deg) rotateY(0deg) translateZ(0px)';
  };

  useEffect(() => {
    const card = cardRef.current;

    const handleBlur = () => setMouseHover(false);
    const handleFocus = () => setMouseHover(true);

    card.addEventListener('blur', handleBlur);
    card.addEventListener('focus', handleFocus);

    return () => {
      card.removeEventListener('blur', handleBlur);
      card.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Wifi Solutions</title>
        <meta property="og:title" content="Wifi Solutions" />
        <meta property="og:description" content="Stay ahead of the curve with Wifi Solutions key technology solutions. Innovation fueled by trust." />
          <meta property="og:image" content={logoblue}/>
          <meta property="og:url" content="https://wifillp.com/"/>
          </Helmet>
          <section className="topsection flexcenter">
            <div className="hoverhomecontainer flexcenter" onMouseMove={handleMouseMove}
              onMouseOut={resetTransform}
              onMouseOver={() => setMouseHover(true)}
              tabIndex="0">
              <div className="container-fluid flexcenter p-0">
                <div className="container tilt" ref={cardRef}>
                  <div className="row mt-5 homerow" id='card'>
                    <div className="col-md-6 flexcenter">
                      <div className="container">
                        <h1 className="text-center" data-aos="fade-up" data-aos-easing="ease-in-sine"
                          data-aos-duration="500" style={{ fontWeight: "bold" }}>Build. Grow. Transform.</h1>
                        <p className="text-center toppara" data-aos="fade-up" data-aos-easing="ease-in-sine"
                          data-aos-duration="800">
                          Stay ahead of the curve with Wifi Solutions key technology solutions.
                          Innovation fueled by trust.                  
                        </p>
                        <div className="w-100 flexcenter mt-5" data-aos="fade-up" data-aos-easing="ease-in-sine"
                          data-aos-duration="1000">
                          {/* <button className="btn btn-primary">About US</button> */}
                          <NavLink to='/contact'>
                            <button className='button3'>Contact Us</button>
                          </NavLink>

                        </div>

                      </div>
                    </div>
                    <div className="col-md-6 flexcenter">
                      <img src={homeimg2} className='img-fluid' alt="" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
          {/* our focus */}
          <section className="ourfocussection flexcenter">

            <div className="container-fluid p-0  servicescontainer">
              <h1 className="headtext text-center mb-5" style={{ fontWeight: "bold" }}>Our Focus</h1>
              <div className="container flexcenter">
                <div className="row w-100 text-center">
                  <div className="col-xl-3 flexcenter">
                    <div className="box-item">
                      <div className="flip-box">
                        <div className="flip-box-front text-center" style={{ backgroundImage: `url(${box1})` }}>
                          <div className="inner color-white">
                            {/* <img width="100px" src={boxwhite} alt="" /> */}
                            <h1 style={{ fontWeight: "bold" }}>Edge</h1>
                            <p>Equipping the work-from-anywhere workforce to handle any workload.
                            </p>

                          </div>
                        </div>
                        <div className="flip-box-back text-center">
                          <div className="inner color-white">
                            <p>Explore what you're capable of doing on the edge.
                            </p>
                            <div className="flexcenter">
                              <NavLink to='/edge'>
                                <button className="button1">Read More</button>
                              </NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-xl-3 flexcenter">
                    <div className="box-item">
                      <div className="flip-box">
                        <div className="flip-box-front text-center" style={{ backgroundImage: `url(${box4})` }}>
                          <div className="inner color-white">
                            {/* <img width="100px" src={taxiwhite} alt="" /> */}


                            <h1 style={{ fontWeight: "bold" }}>Core</h1>
                            <p>Powering up your organization from the backend, and everywhere else.
                            </p>

                          </div>
                        </div>
                        <div className="flip-box-back text-center">
                          <div className="inner color-white">
                            <p>Build a scalable and sustainable IT infrastructure.
                            </p>
                            <div className="flexcenter">
                              <NavLink to='/core'>
                                <button className="button1">Read More</button>
                              </NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-xl-3 flexcenter">
                    <div className="box-item">
                      <div className="flip-box">
                        <div className="flip-box-front text-center" style={{ backgroundImage: `url(${box3})` }}>
                          <div className="inner color-white">
                            {/* <img className="mb-2" width="80px" src={tyrewhite} alt="" /> */}



                            <h1 style={{ fontWeight: "bold" }}>Cloud</h1>
                            <p>Transforming your business with next-generation technology.
                            </p>

                          </div>
                        </div>
                        <div className="flip-box-back text-center">
                          <div className="inner color-white">
                            <p>Future-proof your digital transformation from Edge-Core-Cloud.
                            </p>
                            <div className="flexcenter">
                              <NavLink to='/cloud'>
                                <button className="button1">Read More</button>
                              </NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 flexcenter">
                    <div className="box-item">
                      <div className="flip-box">
                        <div className="flip-box-front text-center" style={{ backgroundImage: `url(${box2})` }}>
                          <div className="inner color-white">
                            {/* <img className="mb-2" width="80px" src={tyrewhite} alt="" /> */}



                            <h1 style={{ fontWeight: "bold" }}>Services</h1>
                            <p>Managing your technology so you can focus on your business.
                            </p>

                          </div>
                        </div>
                        <div className="flip-box-back text-center">
                          <div className="inner color-white">
                            <p>Get the IT support you need.
                            </p>
                            <div className="flexcenter">
                              <NavLink to='/services'>
                                <button className="button1">Read More</button>
                              </NavLink>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* our partner eco */}
          <section className='ecosyssec flexcenter'>
            <div className="container-fluid p-0 flexcenter">
              <div className="container">
                <div className="row w-100">
                  <div className="col-md-6 flexcenter">
                    <img src={homegif2} className='img-fluid' width="400px" alt="" />
                  </div>
                  <div className="col-md-6 flexcenter">
                    <div className="container ecosyscontain pt-5">
                      <h1>OUR PARTNER</h1>
                      <h1>ECOSYSTEM</h1>
                      <p>Strategic partnerships that see your business through from end to end.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </>
        )
}

        export default Home