import React, { useState } from 'react'
// import axios from 'axios'
import { useFormik } from "formik";
import { formSchema } from '../validationSchema'
// import { useNavigate } from 'react-router-dom';
import { succesToaster, errorToaster } from './toaster';
import emailjs from '@emailjs/browser';
// import Email from './smtp';

function Formmsg() {
  const [isloading, setisloading] = useState(false)
  const formik = useFormik({
    initialValues: {
      "companyname": "",
      "name": "",
      "designation": "",
      "email": "",
      "address": "",
      "phone": "",
      "message": ""
    },
    onSubmit: () => {
      // axios.post('https://wifiapi.demowebserver.in/contactus', formik.values,{
      //   headers : {
      //     api_key : process.env.REACT_APP_SECRET_KEY
      //   }
      //   // 'YxkbcXOSirMsNHvGZCoa' 
      // }).then((response) => {
      //   succesToaster('success',`${formik.values.name}, thank you for getting in touch`)
      //   formik.resetForm()
      //   setisloading(false)
      // }).catch((error) => {
      //   console.log(error)
      //   errorToaster('error','Message not sent')
      //   setisloading(false)
      // })
      setisloading(true)
      console.log(formik.values)
      const template_id = 'template_eboxu4r'
      const service_id = 'service_ddjrw9g'
      const public_key = '__9Xm4W44D1vbIskb'
      // params
      const template_params = {
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phone,
        message: formik.values.message,
        company: formik.values.companyname,
        designation: formik.values.designation,
        address: formik.values.address
      }
     emailjs.send(service_id,template_id,template_params,public_key,).then((response)=>{
      console.log(response)
      setisloading(false)
      formik.resetForm()
      succesToaster('success',`${formik.values.name}, thank you for getting in touch`)
     }).catch((err)=>{
      console.log(err)
      setisloading(false)
      errorToaster('error','Message not sent')
      formik.resetForm()
     })
//     Email.send({
//       Host: "smtp.elasticemail.com",
//       Username: "wifisolutions2@gmail.com",
//       Password: "8C2869A2E380785ABC8E693A2DBE852CB200",
//       To: "wifisolutions2@gmail.com",
//       From: "wifisolutions2@gmail.com",
//       Subject: "New Enquiry",
//       Body: `
//       <head>
//   <style>
//       body {
//           font-family: Arial, sans-serif;
//           background-color: #f2f2f2;
//       }

//       .container {
//           max-width: 600px;
//           margin: 0 auto;
//           padding: 20px;
//           background-color: #fff;
//           border-radius: 5px;
//           box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//       }

//       .header {
//           text-align: center;
//           margin-bottom: 20px;
//       }

//       .header h1 {
//           color: #333;
//           font-size: 24px;
//           margin: 0;
//       }

//       .content {
//           margin-bottom: 30px;
//       }

//       .content p {
//           margin: 0 0 10px;
//           line-height: 1.5;
//       }

//       .footer {
//           text-align: center;
//       }

//       .footer p {
//           color: #999;
//           font-size: 14px;
//           margin: 0;
//       }
//   </style>
// </head>
// <body>
// <div class="container">
//         <div class="header">
//             <h1>New Enquiry</h1>
//         </div>
//         <div class="content">
//             <p>Hi,</p>
//             <p>A new enquiry from ${formik.values.name}</p>
//             <p>Company : ${formik.values.company}</p>
//             <p>Designation: ${formik.values.designation}</p>
//             <p>Address : ${formik.values.address}</p>
//             <p>Message : ${formik.values.message}</p>
//             <p>Contact Details</p>
//             <ul>
//                 <li>Mobile : ${formik.values.phone}</li>
//                 <li>Email : ${formik.values.email}</li>
//             </ul>
//         </div>
//         <div class="footer">
//             <p>Best regards,</p>
//             <p>The Team</p>
//         </div>
//     </div>

      
//   </body>`,
//     }).then(
//       (response) => {
//         console.log(response)
//         setisloading(false)
//         formik.resetForm()
//         succesToaster('success', `${formik.values.name}, thank you for getting in touch`)
//       }
//     ).catch((err) => {
//       console.log(err)
//       setisloading(false)
//       formik.resetForm()
//       errorToaster('error', 'Message not sent')
//     });
    },

    validationSchema: formSchema
  })
  // console.log(formik.errors)
  return (
    <>
      <div className="container flexcenter mt-4">

        <div className="container formcontainer">
          <h2 className='text-center mb-4'>Leave Us a Message</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Company Name</label>
              <input type="text" name="companyname" className={formik.errors.companyname && formik.touched.companyname ? "form-control is-invalid" : "form-control"} value={formik.values.companyname} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter company name" />
              {/* validation message*/}
              {formik.errors.companyname && formik.touched.companyname ? <p className='invalid-feedback'>{formik.errors.companyname}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
              <input type="text" name="name" className={formik.errors.name && formik.touched.name ? "form-control is-invalid" : "form-control"} value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter your name" />
              {/* validation message*/}
              {formik.errors.name && formik.touched.name ? <p className='invalid-feedback'>{formik.errors.name}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Designation</label>
              <input type="text" name="designation" className={formik.errors.designation && formik.touched.designation ? "form-control is-invalid" : "form-control"} value={formik.values.designation} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter designation" />
              {/* validation message*/}
              {formik.errors.designation && formik.touched.designation ? <p className='invalid-feedback'>{formik.errors.designation}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
              <input type="text" name="email" className={formik.errors.email && formik.touched.email ? "form-control is-invalid" : "form-control"} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter email address" />
              {/* validation message*/}
              {formik.errors.email && formik.touched.email ? <p className='invalid-feedback'>{formik.errors.email}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
              <input type="text" name="address" className={formik.errors.address && formik.touched.address ? "form-control is-invalid" : "form-control"} value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter your address" />
              {/* validation message*/}
              {formik.errors.address && formik.touched.address ? <p className='invalid-feedback'>{formik.errors.address}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
              <input type="text" name="phone" className={formik.errors.phone && formik.touched.phone ? "form-control is-invalid" : "form-control"} value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter phone number" />
              {/* validation message*/}
              {formik.errors.phone && formik.touched.phone ? <p className='invalid-feedback'>{formik.errors.phone}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label"> Message</label>
              <textarea type="text" rows="4"
                cols="100" name="message" className={formik.errors.message && formik.touched.message ? "form-control is-invalid" : "form-control"} value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter message" />
              {/* validation message*/}
              {formik.errors.message && formik.touched.message ? <p className='invalid-feedback'>{formik.errors.message}</p> : null}
            </div>

            {/* {formik.isValid ? null : <span className="text-danger">Errors in some field</span> } */}
            <div className="container flexcenter">
              <button
                type="submit"
                className={!(formik.isValid && formik.dirty) ? "btn button2 disabled mt-4":"btn button2 mt-4"}
                // className="button2 mt-2"
                disabled={!(formik.isValid && formik.dirty)}
                // onClick={()=>{succesToaster('success',`${formik.values.name}, thank you for getting in touch`)}}

              >
                Submit
              </button>
            </div>
          </form>
          {isloading ? <div className="loadercontain flexcenter">
            <div className="loader">

            </div>
          </div> : null}

        </div>


      </div>
    </>
  );
}

export default Formmsg;
