import React from 'react'
import Formmsg from '../components/Formmsg'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function Contact() {
  return (
    <>
     <Helmet>
       <title>Wifi Solutions |  Contact Us</title>
        <meta property="og:title" content="Wifi Solutions - Contact Us" />
        <meta property="og:description" content="Our primary solutions are geared towards getting you ready to build, develop, deploy, and manage your infrastructure and applications from the edge to the core to the cloud." />
        <meta property="og:image" content={logoblue}/>
        <meta property="og:url" content="https://wifillp.com/contact"/>
      </Helmet>
      <section className='pagenamesection flexcenter'>
        <div className="container-fluid p-0 flexcenter" >
          <div className="container text-center">
            <h2>Contact Us</h2>
            <p><NavLink to='/' style={{textDecoration:'none',color:'white'}}>Home</NavLink> / Contact</p>
          </div>

        </div>

      </section>
      {/*contact form */}
      <section className='pb-5' style={{backgroundColor : '#F8F9FA'}}>
        <div className="conatiner-fluid p-0 flexcenter">
          <div className="row w-100 contactrow">
            <div className="col-md-6 flexcenter">
              <div className="container">
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="container mapcontainer">
                        <iframe title='map2' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1976.1207911482993!2d76.87815288886716!3d8.55759481790113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bfa5b26929db%3A0x5f7806abd72e7145!2sWifi%20Solution%20Technopark%20Thejaswini!5e0!3m2!1sen!2sin!4v1723620422607!5m2!1sen!2sin"style={{ border: 0 }}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                    <div className="col-xl-6 flexcenter">
                      <div className="container text-center mt-2">
                        <h4>Address:</h4>
                        <p><b>Wifi Solutions  </b> <br />
                          Thejaswini  Technopark Campus Phase 1,<br />
                          Electronics Technology Parks-Kerala  <br />
                          Trivandrum ,Kerala <br />
                          India Pin:695581 <br />
                          Tel-No: 0471 4014520<br />
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="container flexcenter">
                <Formmsg></Formmsg>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Contact