import React from 'react'
import {Route, Routes } from 'react-router-dom'
// 
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Core from './pages/Core'
import Edge from './pages/Edge'
import Cloud from './pages/Cloud'
import Services from './pages/Services'
// 
import '../src/App.css'
import Pagelayout from './pages/Pagelayout'
import Pagenotfound from './pages/Pagenotfound'
function App() {
  return (
    <>
      <Routes>
        <Route element={<Pagelayout></Pagelayout>}>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/about' element={<About></About>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/edge' element={<Edge></Edge>}></Route>
        <Route path='/core' element={<Core></Core>}></Route>
        <Route path='/cloud' element={<Cloud></Cloud>}></Route>
        <Route path='/services' element={<Services></Services>}></Route>
        </Route>
        <Route path='*' element={<Pagenotfound></Pagenotfound>}></Route>
      </Routes>
    </>
  )
}

export default App