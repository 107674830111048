import React from 'react'
import illuspeople from '../assets/illuspeople.webp'
import {NavLink} from 'react-router-dom'
// 
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function About() {
  return (
    <>
    <Helmet>
       <title>Wifi Solutions | About Us</title>
        <meta property="og:title" content="Wifi Solutions - About Us" />
        <meta property="og:description" content="At Wifi Solutions, we adhere to our strong corporate values of integrity, commitment, and service. We are dedicated to delivering only the best technologies and providing solutions that offer great value to our customers." />
          <meta property="og:image" content={logoblue}/>
          <meta property="og:url" content="https://wifillp.com/about"/>
      </Helmet>
      <section className='pagenamesection flexcenter'>
        <div className="container-fluid p-0 flexcenter" >
          <div className="container text-center">
            <h2>About Us</h2>
            <p><NavLink to='/' style={{textDecoration:'none',color:'white'}}>Home</NavLink> / About us</p>
          </div>

        </div>

      </section>
      {/*  */}
      <section className='aboutmainsection'>
        <div className="container-fluid p-0">
          <div className="container mt-5 mb-5">
            <h3 className='headtext text-center mb-5'>Committed to delivering technical excellence since 2011
            </h3>
            <p className='textjustify'>At Wifi Solutions, we adhere to our strong corporate values of integrity, commitment, and service. We are dedicated to delivering only the best technologies and providing solutions that offer great value to our customers.
            </p>
            <img className='img-fluid' src={illuspeople} alt="" />
          </div>

        </div>
        <div className="container-fluid p-0 flexcenter pb-5" style={{ backgroundColor: "#F8F9FA" ,minHeight: "60vh"}}>
          <div className="container">
            <h5 className='headtext2'>Our Patrons</h5>
            <p className='textjustify'>
              Wifi Solutions focuses on customer needs, wants and preferences. When we speak of relevance, we mean alignment with customer business requirements. When we speak of transformation, we mean the advent of new technologies in our portfolio with the customers first in mind. When we speak of future proofing our customers business, we mean that we have our customers back in terms of technology, solutions, services and support.
            </p>
            <h5 className='headtext2'>Our People</h5>
            <p className='mb-0 textjustify'>

              To our loyal customers, we are known to have earned recognition in each technology landscape we have participated in. We develop our expertise to match each technology and solution in our wide array of offerings. As a result, we have received certifications for various technical competencies including, but not limited to, networking, cybersecurity, data protection, software-defined data center, cloud services, and various end-user computing.
            </p>
          </div>
        </div>

      </section>
    </>
  )
}

export default About