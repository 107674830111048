import React from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function Services() {
  return (
    <>
    <Helmet>
       <title>Wifi Solutions | Services</title>
        <meta property="og:title" content="Wifi Solutions - Services" />
        <meta property="og:description" content="Wifi Solutions provides end to end business solutions, from planning, implementation, and after-sales services. We ensure that our customers get excellent support to guarantee a good customer experience and satisfaction." />
        <meta property="og:image" content={logoblue}/>
        <meta property="og:url" content="https://wifillp.com/services"/>
      </Helmet>
   
      <section className='pagenamesection flexcenter'>
        <div className="container-fluid p-0 flexcenter" >
          <div className="container text-center">
            <h2>Services</h2>
            <p><NavLink to='/' style={{textDecoration:'none',color:'white'}}>Home</NavLink> / Services</p>
          </div>

        </div>

      </section>
      <section className='edgemainsection pb-5 pt-5'>
        <div className="container-fluid ">
          <h1 className='headtext text-center mb-5'>We See You Through From End To End.</h1>
          <div className="container servicepagecontain p-0">
            <div className='pt-4 pb-4 ps-4 pe-4 text-center' style={{ backgroundColor: "#F8F9FA" }}>
              <p className='textjustify'>
                Wifi Solutions provides end to end business solutions, from planning, implementation, and after-sales services. We ensure that our customers get excellent support to guarantee a good customer experience and satisfaction.               </p>
            </div>
            <div className='pt-4 pb-4 ps-4 pe-4 servicepagebotcon'>
              <p className='text-center'>
                Professional Services <br />
                Consultancy Services <br />
                Managed Services
              </p>
              {/* <p className='text-center'><b>Have you considered moving your business to the cloud?</b></p> */}
              <div className='w-100 flexcenter'>
                <NavLink className='btn btn-primary' to='/contact'>Let us know what you need</NavLink>
              </div>
            </div>
          </div>
          {/*  */}
        </div>

      </section>
    </>
  )
}

export default Services