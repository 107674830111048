import React from 'react'
import { NavLink } from 'react-router-dom'
import coregif from '../assets/coregif.gif'
import { Helmet } from 'react-helmet'
import logoblue from '../assets/WifiLogoBlue.png'

function Core() {
  return (
    <>
    <Helmet>
       <title>Wifi Solutions | Core Solutions</title>
        <meta property="og:title" content="Wifi Solutions - Core Solutions" />
        <meta property="og:description" content="Our primary solutions are geared towards getting you ready to build, develop, deploy, and manage your infrastructure and applications from the edge to the core to the cloud." />
        <meta property="og:image" content={logoblue}/>
        <meta property="og:url" content="https://wifillp.com/core"/>
      </Helmet>
      <section className='pagenamesection flexcenter'>
        <div className="container-fluid p-0 flexcenter" >
          <div className="container text-center">
            <h2>Core Solutions</h2>
            <p><NavLink to='/' style={{textDecoration:'none',color:'white'}}>Home</NavLink> / Core Solutions</p>
          </div>

        </div>

      </section>
      <section className='edgemainsection  pt-5 pb-5'>
        <div className="container-fluid">
          <h1 className='headtext text-center'>Making Transformation from Edge to Core to Cloud Possible</h1>
          <div className="container">
            <img className='img-fluid' src={coregif} alt="" />
          </div>
          <div className="container servicepagecontain p-0">
            <div className='pt-4 pb-4 ps-4 pe-4 text-center' style={{ backgroundColor: "#F8F9FA" }}>
              <p className='textjustify'>
                Our primary solutions are geared towards getting you ready to build, develop, deploy, and manage your infrastructure and applications from the edge to the core to the cloud.              </p>
            </div>
            <div className='pt-4 pb-4 ps-4 pe-4 servicepagebotcon'>
              <p className='text-center'>
                Software Defined Platform (Security, Networking, Management, Application)
                Data Protection and Disaster Recovery <br />
                IT Management and Monitoring <br />
                Cybersecurity Solutions <br />
                Data Center Facility and Management <br />
                Hyper Converged Infrastructure <br />
                Enterprise Networking <br />
                Enterprise Storage <br />


              </p>
              <p className='text-center'><b>Ready to strengthen your competitive edge?</b></p>
              <div className='w-100 flexcenter'>
                <NavLink className='btn btn-primary' to='/contact'>Let us know what you need</NavLink>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default Core